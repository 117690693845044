import { Action, Module, Mutation } from 'vuex-module-decorators';
import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import { Data } from '@/utils/types/WidgetData';
import { BasicTypes } from '@/utils/types/BasicTypes';
import WidgetBaseRepository from '@/repositories/widget/WidgetBaseRepository';
import GraphqlQueryHelper from '@/utils/helpers/GraphqlQueryHelper';
import { deepSearch } from '@/utils/ObjectHelpers';
import Exhibitor from '@/models/graphql/Exhibitor';
import ExhibitorRepository from '@/repositories/ExhibitorRepository';
import * as Sentry from '@sentry/browser';
/* eslint-disable @typescript-eslint/camelcase,prefer-const */

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class TabItemWidgetStore extends WidgetBaseStore {
  private tabItemData: Record<string, Array<Data>> = {};

  private readonly repository = new WidgetBaseRepository();

  private readonly exhibitorRepository = new ExhibitorRepository();

  @Action
  async setDataConfigs(): Promise<void> {
    if (this.widget) {
      const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
      let {
        filter,
        cardType,
        btnGraphql,
        amount,
        orderBy,
        schemaCode,
        displayExtraProperties,
        preload,
      } = JSON.parse(this.widget.payload || '{}');
      preload = preload === undefined ? true : preload;
      let properties: string[] = [];
      if (displayExtraProperties) {
        properties = displayExtraProperties
          ? displayExtraProperties.map((ext: { id: number; key: string }) => ext.key)
          : [];
      }
      const variables = {};
      const tempFilter = JSON.parse(filter || '{}');
      if (Object.keys(tempFilter)
        .includes('filter')) {
        Object.assign(variables, tempFilter.filter);
      } else {
        Object.assign(variables, tempFilter);
      }
      let filterType = '';
      let orderByType = '';
      let operation = '';
      let fragmentName = '';
      let magicArgs: Record<string, string> = {};
      const alias = `${this.widgetStoreName}_tabItem${this.widget.id}`;
      switch (cardType) {
        case 'member':
          filterType = GqlEntityFilterType.COMMUNITY_USER_FILTER;
          orderByType = GqlEntityOrderingType.COMMUNITY_USER_ORDERING;
          fragmentName = 'communityUserCardFragment';
          operation = 'communityUser';
          break;
        case 'exhibitor':
          filterType = GqlEntityFilterType.EXHIBITOR_FILTER;
          orderByType = GqlEntityOrderingType.EXHIBITOR_ORDERING;
          fragmentName = 'exhibitorCardWithEditionFragment';
          operation = 'exhibitor';
          Object.assign(variables, {
            ...{
              editionMappings_some: {
                editionExhibitor_some: {
                  schemaCode,
                },
              },
            },
          });
          break;
        case 'company':
          filterType = GqlEntityFilterType.EXHIBITOR_FILTER;
          orderByType = GqlEntityOrderingType.EXHIBITOR_ORDERING;
          fragmentName = 'exhibitorCardFragment';
          operation = 'exhibitor';
          break;
        case 'session':
          filterType = GqlEntityFilterType.SESSION_FILTER;
          orderByType = GqlEntityOrderingType.SESSION_ORDERING;
          fragmentName = 'sessionCardFragment';
          operation = 'session';
          break;
        case 'videoSession':
          filterType = GqlEntityFilterType.SESSION_FILTER;
          orderByType = GqlEntityOrderingType.SESSION_ORDERING;
          fragmentName = 'sessionForLiveVideoCardFragment';
          operation = 'session';
          break;
        case 'product':
          filterType = GqlEntityFilterType.PRODUCT_FILTER;
          orderByType = GqlEntityOrderingType.PRODUCT_ORDERING;
          fragmentName = 'productCardFragment';
          operation = 'product';
          break;
        case 'largeProduct':
          filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
          orderByType = GqlEntityOrderingType.LARGE_PRODUCT_ORDERING;
          fragmentName = 'largeProductCardFragment';
          operation = 'largeProduct';
          break;
        case 'boatProduct':
          filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
          orderByType = GqlEntityOrderingType.LARGE_PRODUCT_ORDERING;
          fragmentName = properties && properties.length
            ? 'largeProductBoatCardWithExtraPropertiesFragment' : 'largeProductBoatCardFragment';
          operation = 'largeProduct';
          break;
        case 'deal':
          filterType = GqlEntityFilterType.DEAL_FILTER;
          orderByType = GqlEntityOrderingType.DEAL_ORDERING;
          fragmentName = 'dealCardFragment';
          operation = 'deal';
          break;
        case 'speaker':
          filterType = GqlEntityFilterType.SPEAKER_FILTER;
          orderByType = GqlEntityOrderingType.SPEAKER_ORDERING;
          fragmentName = 'speakerCardFragment';
          operation = 'speaker';
          break;
        case 'events':
          filterType = GqlEntityFilterType.SUB_EDITION_FILTER;
          orderByType = GqlEntityOrderingType.SUB_EDITION_ORDERING;
          fragmentName = 'subEditionCardFragment';
          operation = 'subEdition';
          break;
        case 'article':
          filterType = GqlEntityFilterType.ARTICLE_FILTER;
          orderByType = GqlEntityOrderingType.ARTICLE_ORDERING;
          fragmentName = 'articleCardFragment';
          operation = 'article';
          break;
        case 'topic':
          filterType = GqlEntityFilterType.TOPIC_FILTER;
          orderByType = GqlEntityOrderingType.TOPIC_ORDERING;
          fragmentName = 'topicCardFragment';
          operation = 'topic';
          break;
        case 'exhibitorVideo':
          filterType = GqlEntityFilterType.EXHIBITOR_VIDEO_FILTER;
          orderByType = GqlEntityOrderingType.EXHIBITOR_VIDEO_ORDERING;
          fragmentName = 'exhibitorVideoBaseFragment';
          operation = 'exhibitorVideo';
          break;
        case 'package':
          // eslint-disable-next-line no-case-declarations
          let exhibitor: Exhibitor | undefined = this.context.rootGetters['ExhibitorStore/fetchAdminPanelExhibitor'];

          if (exhibitor?._maxSalesPackageRank === undefined) {
            const dependencies = this.context.rootGetters['PageStateManagementStore/getLoadDependencies'];
            if ('ExhibitorStore/loadAdminPanelExhibitor' in dependencies) {
              exhibitor = await dependencies['ExhibitorStore/loadAdminPanelExhibitor'];
            }

            // If it gets here most probably is missing to add a
            // parent widget that gives a company context and loads the required info

            Sentry.captureException(`Config error was found for
            ${this.widget?.type} widget with id ${this.widget?.id}.
            Most probably is missing a parent widget that gives a company context`);
            return;
          }

          filterType = GqlEntityFilterType.SALES_PACKAGE_FILTER;
          orderByType = GqlEntityOrderingType.SALES_PACKAGE_ORDERING;
          fragmentName = 'salesPackageCardFragment';
          operation = 'salesPackage';
          if ('companyId' in args && args.companyId) {
            magicArgs = { filter: `(exhibitorUid: "${args.companyId}")` };
          }
          Object.assign(variables, {
            includedByDefault: false,
            // eslint-disable-next-line no-underscore-dangle
            OR: [{ rank_gt: exhibitor._maxSalesPackageRank }, { rank: null }, { rank: 0 }],
            parentSalesPackage: null,
          });
          break;
        default:
          break;
      }
      let first = {};
      if (amount && amount > 0) {
        first = { first: parseInt(amount, 10) };
      }
      let order = {};
      if (orderBy
        && orderBy.length > 0
        && !orderBy.includes('default')) {
        order = {
          orderBy: {
            value: orderBy.split(','),
            type: orderByType,
          },
        };
      }
      if (btnGraphql && btnGraphql.trim().length > 0) {
        let buttonQuery = btnGraphql;
        if (!buttonQuery.includes('_cachable')) {
          buttonQuery = GraphqlQueryHelper.appendArgumentToQuery(
            buttonQuery,
            '_cachable',
            'BooleanValue',
            !deepSearch(GraphqlQueryHelper.extractFilterFromQuery(buttonQuery), '%authUser%'),
          );
        }
        buttonQuery = `${this.widgetStoreName}_buttonTabItem${this.widget.id}: ${buttonQuery}`;
        if (preload) {
          this.context.commit(
            'WidgetDispatcherStore/setGqlQuery',
            {
              key: this.widgetStoreName,
              value: buttonQuery,
            },
            { root: true },
          );
        }
        this.context.commit(
          'setGqlQuery',
          buttonQuery,
        );
      }

      if (properties && properties.length) {
        this.context.commit(
          'WidgetDispatcherStore/setMagicArgs',
          { extraProperties: properties },
          { root: true },
        );
      }

      const configs = [{
        gqlDefinition: buildQueryDefinition({
          cacheable: cardType === 'member' ? true : !this.context.rootGetters.authUser
            || (!deepSearch(variables, this.context.rootGetters.authUser.uid)
              && !deepSearch(variables, '%authUser%')),
          filter: {
            value: variables,
            type: filterType,
          },
          magicArgs,
          ...order,
          ...first,
        }),
        operation,
        fragmentName,
        alias,
      }];

      this.context.commit('setGqlQueryConfig', configs);
      if (preload) {
        this.context.commit(
          'WidgetDispatcherStore/setGqlQueryConfigs',
          {
            key: this.widgetStoreName,
            values: configs,
          },
          { root: true },
        );
      }
    }
  }

  @Action
  callPreloadQuery(): Promise<void> {
    this.context.commit('load', true);
    const args: Record<string, BasicTypes> = this.context.rootGetters['WidgetDispatcherStore/fetchMagicArgs'];
    if (this.gqlQueryConfig.length > 0) {
      return this.repository.load({
        params: {
          operationName: 'LazyLoadingTabItemData',
          definitions: this.gqlQueryConfig,
          magicArgs: args,
        },
        queries: '',
      })
        .then((response) => {
          Object.keys(response)
            .forEach((k) => {
              this.context.commit(
                'setData',
                {
                  key: k,
                  values: response[k],
                  rootState: this.context.rootState,
                },
              );
            });
        })
        .then(() => {
          this.context.commit('mapper');
          this.context.commit('load', false);
        });
    }
    this.context.commit('load', false);
    return Promise.resolve();
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    const keys = data.key.split('_');
    Object.assign(this.tabItemData, { [keys[1]]: data.values });
  }

  @Mutation
  setGqlQuery(query: string): void {
    this.query += query;
  }

  @Mutation
  mapper(): void {
    if (this.widget) {
      let data: Data[] = [];
      let btnRouteData: Data[] | null = null;
      const btnKey = `buttonTabItem${this.widget.id}`;
      const tabKey = `tabItem${this.widget.id}`;
      if (Object.keys(this.tabItemData)
        .includes(tabKey)) {
        data = this.tabItemData[tabKey];
      }
      if (Object.keys(this.tabItemData)
        .includes(btnKey)) {
        btnRouteData = this.tabItemData[btnKey];
      }
      this.mapping = {
        ...this.payload,
        uid: this.widget.uid,
        widget: this.widget,
        data,
        btnRouteData,
      };
    }
  }
}
