


























































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { BLink } from 'bootstrap-vue';
import PillComponent from '@/components/pill/PillComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import CategoryDomainContext from '@/models/graphql/CategoryDomainContext';
import CategoryDomainContextEnum from '@/utils/enums/CategoryDomainContext';
import { Getter, namespace } from 'vuex-class';
import CategorySelectorModal from '@/components/modals/CategorySelectorModal.vue';
import Exhibitor from '@/models/graphql/Exhibitor';
import Category from '@/models/graphql/Category';
import Variant from '@/utils/enums/Variant';
import PillWidget from '@/components/pill/PillWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ToastActionType from '@/utils/enums/ToastActionType';
import { Data } from '@/utils/types/WidgetData';
import EntityType from '@/utils/enums/EntityType';
import Product from '@/models/graphql/Product';
import Deal from '@/models/graphql/Deal';
import LargeProduct from '@/models/graphql/LargeProduct';
import Context from '@/models/graphql/Context';
import SlotTypeEnum from '@/utils/enums/SlotTypeEnum';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import GUUID from '@/utils/GUUID';
import Session from '@/models/graphql/Session';

const categoryDomainContextStore = namespace('CategoryDomainContextStore');
const exhibitorStore = namespace('ExhibitorStore');
const salesPackageLimitStore = namespace('SalesPackageLimitStore');

@Component({
  components: {
    FontAwesomeComponent,
    PillWidget,
    CategorySelectorModal,
    ButtonIconComponent,
    PillComponent,
    BLink,
  },
})
export default class CategorySelectorComponent extends Vue {
  @Prop({ required: true })
  private entity!: Data;

  @Prop({ required: true })
  private entityType!: EntityType;

  @Prop({ required: false, default: false })
  private disabled!: boolean;

  @Prop({ required: false, default: true })
  private cardLayout!: boolean;

  @Prop({ required: false, default: false })
  private fieldLayout!: boolean;

  @Prop({ required: false, default: false })
  private isMandatory!: boolean;

  @categoryDomainContextStore.Action
  private loadDomainsByMultipleContexts!: (context: string[]) => void;

  @categoryDomainContextStore.Getter
  private fetchContexts!: CategoryDomainContext[];

  @exhibitorStore.Action
  private removeCategoriesFromExhibitor!: (payload: {
    uid: string;
    categoryUid: string[];
  }) => Promise<void>;

  @exhibitorStore.Action
  private addCategoriesToExhibitor!: (payload: {
    uid: string;
    categoryUid: string[];
  }) => Promise<void>;

  @salesPackageLimitStore.Action
  private filterSlots!: (payload: { exhibitorUid: string; code: string }) => Promise<{ type: string; limit: number }[]>;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private variantEnum = Variant;

  private selectedCategories: Category[] = [];

  private domainList: CategoryDomainContext[] = [];

  private selectedIds: string[] = [];

  private currentContext = '';

  private categoryLimits: { type: string; limit: number }[] = [];

  private modalId = `company-selector-category-modal-${GUUID.uuidv4()}`;

  private castedEntity: Exhibitor | Product | LargeProduct | Deal | { uid: string; categories: Category[] }
    = { uid: '', categories: [] };

  private get isEditableContexts(): boolean {
    return this.domainList.filter((d) => d.editable).length > 0;
  }

  private get isNewLimitCalculationFeatureEnabled(): boolean {
    const salesServiceFeature = this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE);
    return salesServiceFeature && salesServiceFeature.enabled;
  }

  created(): void {
    if (this.entityType === EntityType.EXHIBITOR && this.isNewLimitCalculationFeatureEnabled) {
      this.filterSlots({
        exhibitorUid: this.$route.params.companyId,
        code: SlotTypeEnum.CATEGORY_SLOT,
      }).then((response) => {
        this.categoryLimits = response;
      });
    }
    switch (this.entityType) {
      case EntityType.EXHIBITOR:
        this.currentContext = CategoryDomainContextEnum.COMPANY_CMS;
        break;
      case EntityType.PRODUCT:
        this.currentContext = CategoryDomainContextEnum.PRODUCT_CMS;
        break;
      case EntityType.LARGE_PRODUCT:
        this.currentContext = CategoryDomainContextEnum.LARGE_PRODUCT_CMS;
        break;
      case EntityType.DEAL:
        this.currentContext = CategoryDomainContextEnum.DEAL_DETAIL;
        break;
      case EntityType.SESSION:
        this.currentContext = CategoryDomainContextEnum.SESSION_CMS;
        break;
      default:
        break;
    }
    if (this.currentContext) {
      this.loadDomainsByMultipleContexts([this.currentContext]);
    }
  }

  @Watch('entity', { immediate: true, deep: true })
  private updateEntity(): void {
    this.setCastedEntity();
    this.domainTabList();
  }

  private setCastedEntity(): void {
    switch (this.entityType) {
      case EntityType.EXHIBITOR:
        this.castedEntity = Exhibitor.hydrate(this.entity);
        break;

      case EntityType.PRODUCT:
        if (this.entity?.uid) {
          this.castedEntity = Product.hydrate(this.entity);
        } else {
          this.castedEntity = { categories: [] } as unknown as Product;
        }
        break;

      case EntityType.LARGE_PRODUCT:
        if (this.entity?.uid) {
          this.castedEntity = LargeProduct.hydrate(this.entity);
        } else {
          this.castedEntity = { categories: [] } as unknown as LargeProduct;
        }
        break;

      case EntityType.DEAL:
        if (this.entity?.uid) {
          this.castedEntity = Deal.hydrate(this.entity);
        } else {
          this.castedEntity = { categories: [] } as unknown as Deal;
        }
        break;
      case EntityType.SESSION:
        if (this.entity?.uid) {
          this.castedEntity = Session.hydrate(this.entity);
        } else {
          this.castedEntity = { categories: [] } as unknown as Session;
        }
        break;

      default:
        break;
    }
  }

  @Watch('castedEntity.categories', { deep: true })
  @Watch('castedEntity', { deep: true })
  @Watch('domainList', { deep: true })
  private setSelectedCategories(): void {
    if (this.castedEntity && this.castedEntity.categories) {
      const localDomainStringList = this.domainList.map((value: CategoryDomainContext) => value.domain);
      this.selectedCategories = this.castedEntity.categories
        .filter((category: Category) => (category.domain
          ? localDomainStringList.includes(category.domain)
          : false))
        .sort((a, b) => {
          if (a.displaySequence === null && b.displaySequence === null) {
            return a.name < b.name ? -1 : 1;
          }
          if (a.displaySequence === null && b.displaySequence === null) {
            return a.name < b.name ? -1 : 1;
          }
          if (a.displaySequence !== null && b.displaySequence === null) {
            return -1;
          }
          if (a.displaySequence === null && b.displaySequence !== null) {
            return 1;
          }
          // eslint-disable-next-line no-nested-ternary
          return a.displaySequence !== null
          && b.displaySequence !== null
          && (a.displaySequence as number) < (b.displaySequence as number)
            ? -1
            // eslint-disable-next-line no-nested-ternary
            : a.displaySequence !== null
            && b.displaySequence !== null
            && (a.displaySequence as number) > (b.displaySequence as number)
              ? 1 : a.name < b.name ? -1 : 1;
        });
    } else {
      this.selectedCategories = [];
    }
    this.setSelectedUidList();
  }

  private isEditableCategory(contexts: Array<Context> | undefined): boolean {
    if (contexts && contexts.length > 0) {
      const myContext = contexts.find((c) => c.context === this.currentContext);
      if (myContext) {
        return this.domainList.filter((c) => c.uid === myContext.uid && c.editable).length > 0;
      }
    }
    return true;
  }

  private modalConfirm(payload: { selectedIds: string[]; selectedList: Category[] }): void {
    this.selectedIds = payload.selectedIds;
    const toDeleteUid: string[] = [];
    const toAddUid: string[] = [];
    payload.selectedIds.forEach((uid) => {
      if (this.castedEntity.categories
        && this.castedEntity.categories.find((category) => category.uid === uid) === undefined) {
        toAddUid.push(uid);
      }
    });
    if (this.castedEntity.categories) {
      this.castedEntity.categories.forEach((category) => {
        if (payload.selectedIds.find((uid) => category.uid === uid) === undefined) {
          toDeleteUid.push(category.uid);
        }
      });
    }
    if (toDeleteUid.length > 0) {
      this.onDeleteCategories(toDeleteUid);
    }
    if (toAddUid.length > 0) {
      this.onAddCategories(toAddUid, payload.selectedList);
    }
    this.$emit('update-categories',
      {
        ids: payload.selectedList,
      });
    this.$bvModal.hide(this.modalId);
  }

  @Watch('fetchContexts')
  private domainTabList(): void {
    const list: CategoryDomainContext[] = [];
    this.fetchContexts
      .forEach((domainContext: CategoryDomainContext) => {
        if (domainContext.domain) {
          list.push(domainContext);
        }
      });
    this.domainList = list;
  }

  // eslint-disable-next-line class-methods-use-this
  private getUniqueDomains(categories: Category[]): string[] {
    const domains: string[] = [];
    if (categories.length > 0) {
      categories.forEach((category: Category) => {
        if (category.domain && !domains.includes(category.domain)) {
          domains.push(category.domain);
        }
      });
    }
    return domains;
  }

  private setSelectedUidList(): void {
    const uidList: string[] = [];
    this.selectedCategories.forEach((category: Category) => {
      uidList.push(category.uid);
    });
    this.selectedIds = uidList;
  }

  private onDeleteCategories(categoriesUid: string[]): void {
    switch (this.entityType) {
      case EntityType.EXHIBITOR:
        this.removeCategoriesFromExhibitor({
          uid: this.castedEntity.uid,
          categoryUid: categoriesUid,
        });
        categoriesUid.forEach((uid) => {
          if (this.castedEntity.categories) {
            const index = this.castedEntity.categories
              .findIndex((category) => category.uid === uid);
            if (index >= 0) {
              this.castedEntity.categories.splice(index, 1);
              this.$emit('on-show-toast', ToastActionType.UPDATE_COMPANY_INFORMATION);
            }
          }
        });
        break;

      case EntityType.PRODUCT:
      case EntityType.LARGE_PRODUCT:
        categoriesUid
          .forEach((uid) => {
            if (this.castedEntity.categories) {
              const index = this.castedEntity.categories
                .findIndex((category) => category.uid === uid);
              if (index >= 0) {
                this.castedEntity.categories.splice(index, 1);
              }
            }
          });
        this.$emit('update-categories',
          {
            ids: this.castedEntity.categories,
          });
        break;

      case EntityType.DEAL:
      case EntityType.SESSION:
        categoriesUid
          .forEach((uid) => {
            if (this.castedEntity.categories) {
              const index = this.castedEntity.categories
                .findIndex((category) => category.uid === uid);
              if (index >= 0) {
                this.castedEntity.categories.splice(index, 1);
              }
            }
          });
        this.$emit('update-categories',
          {
            ids: this.castedEntity.categories,
          });
        break;

      default:
        break;
    }
  }

  private onAddCategories(categoriesUid: string[], sendCategories: Category[]): void {
    switch (this.entityType) {
      case EntityType.EXHIBITOR:
        if (this.castedEntity.categories) {
          this.addCategoriesToExhibitor({
            uid: this.castedEntity.uid,
            categoryUid: categoriesUid,
          });
          categoriesUid.forEach((uid) => {
            const index = sendCategories.findIndex((category) => category.uid === uid);
            if (index >= 0 && this.castedEntity.categories) {
              this.castedEntity.categories.push(sendCategories[index]);
            }
          });
          this.$emit('on-show-toast', ToastActionType.UPDATE_COMPANY_INFORMATION);
        }
        break;

      case EntityType.PRODUCT:
      case EntityType.LARGE_PRODUCT:
        categoriesUid
          .forEach((uid) => {
            const index = sendCategories.findIndex((category) => category.uid === uid);
            if (index >= 0 && this.castedEntity.categories) {
              this.castedEntity.categories.push(sendCategories[index]);
            }
          });
        break;

      case EntityType.DEAL:
      case EntityType.SESSION:
        categoriesUid
          .forEach((uid) => {
            const index = sendCategories.findIndex((category) => category.uid === uid);
            if (index >= 0 && this.castedEntity.categories) {
              this.castedEntity.categories.push(sendCategories[index]);
            }
          });
        this.$emit('update-categories',
          {
            ids: this.selectedCategories,
          });
        break;

      default:
        break;
    }
  }
}
